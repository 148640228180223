import axios from "axios";

console.log(process.env)
console.log('extranet')
const baseUrlForEnvironment = process.env.NODE_ENV ==='development' ? 'http://pmeinter.localhost' : 'https://pmeinter.com';
// const baseUrlForEnvironment = 'https://pme.stagewink.com';
// const baseUrlForEnvironment = 'http://pme.test';
// process.env.NODE_ENV === 'development' ? 'https://pme.stagewink.com' : 'https://pmeinter.com';

const tokenAxios = axios.create({
    baseURL: baseUrlForEnvironment + '/wp-json/jwt-auth/v1/token',
});

const pagesAxios = axios.create({
    baseURL: baseUrlForEnvironment + '/wp-json/pme/extranet/v1/page/',
});

export {tokenAxios, pagesAxios, baseUrlForEnvironment};