import * as React from 'react';
import { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import { useParams } from 'react-router-dom';
import {pagesAxios} from '../components/utils/Axios'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ListComponent from '../components/ListComponent';
import MosaicComponent from '../components/MosaicComponent';
import MosaicListSwitch from '../components/MosaicListSwitch';
import Searchbar from '../components/Searchbar';
import Breadcrumbs from '../components/Breadcrumbs';
import Loading from '../components/Loading';

function SingleProduitInformatique() {
    let { id } = useParams();
    const [data, setData] = useState<any>({});
    const [dataFiltered, setDataFiltered] = useState<any>([]);
    const [displayList, setDisplayList] = useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);

    useEffect(() => {
        pagesAxios.get('produits-informatiques/' + id).then(response => {
            const receivedData = response.data.data;
            setData(receivedData);
            setDataFiltered(receivedData?.fields?.categories);
            setLoading(false);
        });
    },[]);

    const listDisplayed = dataFiltered?.map((category:any, index: number) => {
        return (
            <div key={index} className='list-component'>
                {
                category.documents.length > 0 ?
                    <div className='list-component__container'>
                        <h4>{category?.title}</h4>
                        <ListComponent 
                            data={category?.documents} 
                            tabs={[
                                {title: 'Nom', keys:['name'], isTheBiggest: true}, 
                                {title: 'Dernière MAJ', keys: ['updated_at']}, 
                                {title: 'Taille', keys: ['file', 'filesize']}, 
                            ]} 
                            linkKeys={['file', 'link']}
                        />
                    </div>
                : null
                }
            </div>
        );
    });

    return (
        <Layout title={data?.title} classes="single-info">
            {loading ? <Loading /> : 
                <div className='single-info__container inner-div'>
                    <h1 dangerouslySetInnerHTML={{__html: data.title}}></h1> 
                    <Breadcrumbs />
                    <div className='research-container'>
                        <a href='/produits-informatiques'>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </a>
                        <Searchbar data={data} onDataFiltered={setDataFiltered} firstKey='categories' secondKey='documents' noFilter={false} />
                    </div>
                    <MosaicListSwitch onDisplaySelected={setDisplayList} />
                    <div className='single-info__content'>
                        {
                            displayList ?
                                listDisplayed
                            : <MosaicComponent data={dataFiltered} />
                        }
                        
                    </div>
                </div>
            }
        </Layout>
    );
}

export default SingleProduitInformatique;
