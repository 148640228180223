import * as React from 'react';
import Header from './Header';
import Menu from './Menu';
import {tokenAxios} from '../components/utils/Axios';
import { getToken, validateToken } from '../components/utils/Common';
import { useNavigate } from 'react-router-dom';
import MenuMobile from './MenuMobile';
import Loading from '../components/Loading';

interface LayoutProps {
  title: string;
  children: any,
  classes?: string;
}

const Layout: React.FC<LayoutProps> = ({title, children, classes }) => {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
    if (!validateToken()) {
        tokenAxios.post('/validate', {}, 
            { headers: {"Authorization" : `Bearer ${getToken()}`} })
        .then(response => {
          if(response.data.statusCode === 403) {
            navigate('/connexion');
          }
          setLoading(false);
        }).catch(error => {
          navigate('/connexion');
          setLoading(false);
        });
      } else {
          setLoading(false);
      }
    }, []);
 

  return (
    <React.Fragment>
      <header>
        <title>PME Internotaire extranet - {title}</title>
        <link rel="icon" type="image/png" href="/images/favicon.png" />
      </header>

      <div className='header-wrapper'>
        <Header onMobileMenuOpen={setIsMobileMenuOpen} />
      </div>

      { loading ? <Loading /> : 
        <main className={`${classes} layout`} >
          <div className='background'>
            <div className='background__left'></div>
            <div className='background__right'></div>
          </div>
          <div className='layout-container'>
            <div className='menu-wrapper'>
              <Menu />
            </div>
            {isMobileMenuOpen ?
              <MenuMobile />
              : children
            }
          </div>
        </main>
      }
    </React.Fragment>
  )
}

export default Layout;
