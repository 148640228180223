import * as React from 'react';

interface MembersProps {
    items: any;
}

const MembersTab = ({items}: MembersProps) => {
  return (
    <div className='conseil-admin-page__member-list'>
        {items?.map(function(item:any,index:number) {
            return (
                <div key={index} className='admin-card'>
                    <h3>{item?.first_name} {item?.last_name}</h3>
                    <p className='role'>{item?.job_title}</p>
                    <p className='company'>{item?.branch}</p>
                </div>
            )
        })}
    </div>  
  )
}

export default MembersTab;