import * as React from 'react'; 

function DropdownComite({categories, data, onDataSelected, isEspaceCollab}: any) {
    var _ = require('lodash');

  React.useEffect(() => {
    handleDataSelected('');
  }, [data]);

  const dropdownDisplayed = categories.map((value: any) => {
    return <option key={value.key} value={value.key}>{value.title}</option>
  });

  let handleDataSelected = (value: any) => {
    onDataSelected(value);
  }

  if (isEspaceCollab) {
    handleDataSelected = (value: any) => {
      let clone = _.cloneDeep(data)
      if(value === '') {
        onDataSelected([]);
      } else {

        let selectedCategory = clone.filter((cat:any) => cat.key === 'documents');
        let filteredItems = selectedCategory[0].items.filter((cat:any) => cat.categories.slug === value);
        selectedCategory[0].items=filteredItems ;
        selectedCategory[0].searchTerm = value;
        onDataSelected(selectedCategory);
      }
    }
  }
  

  return (
    <div className='dropdown-comite'>
        <select onChange={(event) => handleDataSelected(event.target.value)}>
          <option key='empty' value=''>Sélectionnez une catégorie</option>
          {dropdownDisplayed}
        </select>
    </div>
  );
}

export default DropdownComite;
