import * as React from 'react';
import FolderImg from '../resources/images/folder-icon.svg';

interface FoldersProps {
    yearArray: any;
    yearSelected: any;
    yearPDFSelected:any
    onYearSelected: any;
    data?: any
}

const FoldersTab = ({yearArray, yearSelected, yearPDFSelected, onYearSelected}: FoldersProps) => {
   
  return (
    <div className='folder-tab'>
        <div className='years-tabs'>
            {yearArray.map(function(year:any) {
                return (
                    <button key={year} className={`year-button ${yearSelected === year ? 'active' : ''}`}  onClick={() => onYearSelected(year)}>
                        <img src={FolderImg} alt="icon fichier" />
                        <p className='year'>{year}</p>
                    </button>
                )
            })}
        </div>
        <div className='years-pdf'>
            {yearPDFSelected?.map(function(item:any,index:number) {
                return (
                    <a className='pdf' target='_blank' href={item.link} key={index} >{item.title}</a>
                )
            })}
        </div>
    </div>
    )
}

export default FoldersTab;