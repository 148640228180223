import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

interface MosaicProps {
    data: any[];
}

function MosaicComponent({data}: MosaicProps) {

    const elementsMosaic = data?.map((category:any, index: number) => {
        return (
            <div key={index} className='mosaic-component'>
                {
                category.documents.length > 0 ?
                    <div className='mosaic-component__container'>
                        <h4>{category?.title}</h4>
                        <div className='mosaic-component__container__grid'>
                            {
                                category?.documents?.map((doc:any, index: number) => {
                                    return (
                                        <a key={index} href={doc?.file?.link} className='mosaic-component__container__grid__card' target='_blank' rel="noreferrer">
                                            <p className='element-name'>{doc?.name}</p>
                                            <FontAwesomeIcon className='element-type' icon={faFilePdf} />
                                            <p className='text-orange'>Dern. Màj: {doc?.updated_at}</p>
                                            <p className='text-orange'>Taille: {doc?.file?.filesize}</p>
                                        </a>
                                    )
                                })
                            }
                        </div>
                    </div>
                    : null
                }
            </div>
        );
    });


    return (
        <div>{elementsMosaic}</div>
    );
}

export default MosaicComponent;
