import * as React from 'react';
import Pagination from '../components/Pagination';
import filePdf from '../resources/images/file-pdf-solid.svg';
import fileWord from '../resources/images/file-word-solid.svg';
import folderImg from '../resources/images/folder-icon.svg';
interface ListProps {
    data: any[];
    tabs: {title: string, keys: string[], isTheBiggest?: boolean}[];
    linkKeys: string[];
}

function ListComponent({data, tabs, linkKeys}: ListProps) {
    const [currentPage, setCurrentPage] = React.useState(1);
    const [postePerPage] = React.useState(10);

    const tabsDisplayed = tabs.map((tab: any, index: number) => {
        return <p key={index} className={tab.isTheBiggest ? 'bigger' : ''}>{tab.title}</p>
    });

    const getItemClasses = (tab: any, extraClasses: string) => {
        let classes = tab.isTheBiggest ? 'bigger' : '';
        return classes + ' ' + extraClasses;
    }

    const indexOfLastPost = currentPage * postePerPage;
    const indexOfFirstPost = indexOfLastPost - postePerPage;
    const currentPosts = data.slice(indexOfFirstPost,indexOfLastPost)

    const paginate = (pageNumber:any) => setCurrentPage(pageNumber);

    const elementsList = currentPosts?.map((element:any, index: number) => {
        let linkTest = element;
        let finalLink: string = '';
        linkKeys.forEach((key: any, index: number) => {
            linkTest = linkTest[key];
            if(index === linkKeys.length-1) {
                finalLink = linkTest;
            }
        });
        
        return (
            <a key={index} href={finalLink} className='list-component__container__content' target='_blank' rel="noreferrer"
            style={{gridTemplateColumns: `repeat(${tabs.length + 2}, minmax(0, 1fr))`}}>
            {
                tabs.map((tab: any, index: number) => {
                    let customClass = '';
                    let itemTest = element;
                    let finalItem = '';
                    tab.keys.forEach((key: any, index: number) => {

                        if(tab.keys === 'name' && itemTest.file.subtype){
                            let itemTestString = itemTest[key];

                            if(index === tab.keys.length-1) {
                                let addedType 
                                if(itemTest.file.subtype === 'pdf'){
                                     addedType = '<img className="typeFile" src='+filePdf+' alt='+itemTest.file?.subtype+'>' 
                                } else if(itemTest.file.subtype === 'doc' || itemTest.file.subtype === 'docx' || itemTest.file.subtype === 'msword'){
                                    addedType = '<img className="typeFile" src='+fileWord+' alt='+itemTest.file?.subtype+'>' 
                                } else {
                                    addedType = '<img className="typeFile" src='+folderImg+' alt='+itemTest.file?.subtype+'>' 
                                }
                                finalItem = addedType;
                                if(itemTestString.length > 50){
                                    finalItem += itemTestString.substring(0,50) + ' ...';
                                } else {
                                    finalItem += itemTestString;
                                }
                            }
                        } else {

                            if (key === "categories" ) {
                                if (!itemTest.hasOwnProperty('categories') || !Array.isArray(itemTest.categories)) {
                                    itemTest.categories = [];
                                }
                                const strings = itemTest.categories.map((item: any) => {return item.name});
                                finalItem = strings.join('<br/>');
                                customClass = "small-text";
                            } else {
                                itemTest = itemTest[key];
                                if(index === tab.keys.length-1) {
                                    finalItem = itemTest;
                                }
                            }  
                        }  
                    });
                
                    return (
                        <p key={index} className={getItemClasses(tab, customClass)} dangerouslySetInnerHTML={{__html: finalItem}}></p>
                    );
                })
            }
            </a>
        );
    });

    return (
        <div>
            <div className='list-component__container__tabs'
            style={{gridTemplateColumns: `repeat(${tabs.length + 2}, minmax(0, 1fr))`}}>
                {tabsDisplayed}
            </div>
            <div className="list-component__container__list">
                <div className='list-items'>{elementsList} </div>
                
                <Pagination postePerPage={postePerPage} currentPage={currentPage} totalPosts={data.length} paginate={paginate}/>
            </div>
            
        </div>
    );
}

export default ListComponent;
