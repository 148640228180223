import * as React from 'react';
import { useState, useEffect } from 'react';
import Searchbar from '../components/Searchbar';
import DropdownComite from '../components/DropdownComite';
import ComitesExpertisesFiltered from './ComitesExpertisesFiltered';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const ComitesExpertisesByTabSimplified = ({dataByTab}: any) => {
    const [dataFiltered, setDataFiltered] = useState<any>(null);
    const [dataSearched, setDataSearched] = useState<any>(null);
    const [categoryFilter, setCategoryFilter] = useState<any>(null);
    const [tabSelected, setTabSelected] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchValue, setSearchValue] = useState<string>('');
    const [yearSelected, setYearSelected] = useState<string>('');

    useEffect(() => {
        if (dataByTab !== void(0) && dataByTab && !Array.isArray(dataByTab) || dataByTab.length === 0) {
            setDataFiltered([]);
            return;
        }
        let data = dataByTab[0].items;

        if (data === void(0) || !data) {
            setDataFiltered([]);
            return;
        }

        if (searchValue && searchValue !== "") {
            if (tabSelected === "documents") {
                data = data.filter((el: any) => el?.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
            } else if (tabSelected === "records") {
                data = data.filter((el: any) => { 
                    return (
                        el?.title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
                   )
                });
            } else {
                data = data.filter((el: any) => { 
                    return (
                        el?.first_name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
                        el?.last_name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
                   )
                });
            }
        } else {
            data = dataByTab[0].items;
        }

        if (Array.isArray(data) && data.length && categoryFilter && categoryFilter !== "" && data[0].hasOwnProperty('categories')) {
            data = data.filter((item:any) => {
                let isValid = false;
                item.categories?.forEach((cat: any) => {
                    if (cat.slug === categoryFilter) {
                        isValid = true;
                    }
                });

                return isValid;
            });
        }
        setDataFiltered(data);
    }, [searchValue, categoryFilter, yearSelected]); 


    useEffect(() => {
        setDataFiltered(dataByTab ? dataByTab[0]?.items : null);
        setTabSelected(dataByTab ? dataByTab[0]?.key : null);
        setLoading(false);
    }, [dataByTab]); 

    useEffect(() => {
        setSearchValue('');
        setCategoryFilter('');
    }, [tabSelected]);

    const getDropDown: any = (type: any, data: any) => {
        let d = data;
        if (Array.isArray(data)) {
            d = data[0];
        }
        if (!d || d === void(0)) {
            return (<></>);
        }
        if(type === 'documents' && d.hasOwnProperty('categories')) {
            console.log("cat", d);
            return (
                <DropdownComite 
                    categories={d.categories} 
                    data={dataByTab}
                    onDataSelected={setCategoryFilter}
                />
            );
        } else {
            return (<></>);
        }
    }


    return (
        <>
            <div className='comites-expertises-page__filters'>
                <form className="generic-searchbar searchbar searchbar-mobile -single">
                    <div className="article-choice-div">
                        <input type="text" className="article-choice" placeholder="Recherche..." value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                        <label><FontAwesomeIcon className='text-orange' icon={faSearch} /><input className="submit-btn" /></label>
                    </div>
                </form>
                { tabSelected && dataFiltered && !loading ? getDropDown(tabSelected, dataByTab) : ''}
            </div>
            { dataFiltered && !loading ? <ComitesExpertisesFiltered doYearSelected={setYearSelected} dataFiltered={dataFiltered} tabSelected={tabSelected} /> : null }
        </>
    );
}

export default ComitesExpertisesByTabSimplified;
