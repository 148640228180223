import * as React from 'react';
import { Link } from 'react-router-dom';
import {routes} from './utils/Routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../resources/images/logo_PME.png';
import { useLocation } from "react-router-dom";
import { faArrowRightFromBracket, faDatabase, faPencil } from '@fortawesome/free-solid-svg-icons';
import { getUser } from './utils/Common';
import { baseUrlForEnvironment } from './utils/Axios';
import { getToken } from '../components/utils/Common';

function Menu() {
    const location = useLocation();
    const menuLinks = routes.sort((a, b) => a.order < b.order ? -1 : 1).map((route, index) => {
        return (
            <div key={index}>
            {
                route.icon ? 
             
                <li className={route.path === location.pathname ? 'active' : ''}>
                    <FontAwesomeIcon icon={route.icon} /> 
                    <Link to={route.path}>{route.title}</Link>
                </li>
                : null
            }
            </div>
        );
    });

  return (
    <div className='menu'>
        <img className='menu__logo' src={logo} alt='Logo' />
        <h2>Bonjour</h2>
        <div className='user-info'>
            <p>{getUser()?.firstName} {getUser()?.lastName}</p>
        </div>
        <ul>
            {menuLinks}
            <li>
                <FontAwesomeIcon icon={faDatabase} /> 
                <a href="https://bd.pmeinter.ca" target='_blank' rel='noreferrer'>Base de données</a>
            </li>
            <li className='logout'>
                <FontAwesomeIcon icon={faArrowRightFromBracket} /> 
                <Link to="/connexion">Déconnexion</Link>
            </li>
        </ul>
    </div>
  )
}

export default Menu;