import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripHorizontal, faList } from '@fortawesome/free-solid-svg-icons';

function MosaicListSwitch({onDisplaySelected}: any) {
    const [displayList, setDisplayList] = React.useState<boolean>(false);

    function handleSwitchSelection () {
        setDisplayList(prevValue => !prevValue);
        onDisplaySelected(!displayList);
    }

    return (
        <div onClick={() => handleSwitchSelection()} className='display-style'>
            <FontAwesomeIcon icon={displayList ? faGripHorizontal : faList} />
            <p>{displayList ? 'Mosaïque' : 'Liste'}</p>
        </div>
    );
}

export default MosaicListSwitch;
