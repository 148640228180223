import * as React from 'react';



const Pagination = ({postePerPage,currentPage,totalPosts,paginate}: { postePerPage: any,currentPage: any, totalPosts: any, paginate:any }) => {
    
    const pageNumbers = [];
    for (let index = 1; index <= Math.ceil(totalPosts / postePerPage); index++) {
      pageNumbers.push(index);
    }

    return (
       <nav>
           <ul  className={pageNumbers.length == 1 ? 'pagination disabled' : 'pagination'}>
               {pageNumbers.map(number=>(
                   <li key={number}  className={currentPage == number ? 'active page-item' : 'page-item'}>
                        <a onClick={() => paginate(number)} className='page-link'>{number}</a>
                   </li>
               ))}
           </ul>
       </nav>
    );
}

export default Pagination;
