import * as React from 'react';

interface ToolsProps {
    content:any,
    linkKey: string,
    buttonTitle: string
}

const ToolsTab = ({content, linkKey, buttonTitle}: ToolsProps) => {
    return (
        <div className='tool-tab'>
            {Object.keys(content).map(function(section:any,index:number) {
                return(
                    <div key={index} className="tool-section">
                        <h3 className={section === 'undefined' ? 'hidden' : ''}>{section}</h3>
                        <div className='tool-tab__list'>
                            {
                                content[section].map((el:any,index:number) => {
                                    return(
                                        <div key={index} className="tool-flex">
                                            <div className='tool-infos'>
                                                <h5 dangerouslySetInnerHTML={{ __html: el.title}} className='tool-title'></h5>
                                                <p className='tool-description'>{el.description}</p>
                                            </div>
                                            {
                                                el[linkKey].external ?
                                                <a className='generic-button--dark-blue flex items-center justify-center gap-5' href={ el[linkKey].url } target={el[linkKey].target}>
                                                    {buttonTitle}
                                                </a>
                                                : 
                                                <a className='generic-button--dark-blue flex items-center justify-center gap-5' href={ el[linkKey].url } target={el[linkKey].target}>
                                                    {buttonTitle}
                                                </a>
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default ToolsTab;