import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faHandshake, faNewspaper } from "@fortawesome/free-regular-svg-icons";
import { faCartFlatbed, faCircleQuestion, faComputer, faHouse, faPenRuler, faPhone, faStar, faUsersLine } from "@fortawesome/free-solid-svg-icons";
import { RouteObject } from "react-router-dom";
import Accueil from "../../pages/Accueil";
import AppelOffres from "../../pages/AppelOffres";
import Connexion from "../../pages/Connexion";
import ProduitsInformatique from "../../pages/ProduitsInformatique";
import Questions from "../../pages/Questions";
import ConseilAdmin from "../../pages/ConseilAdministration";
import SingleProduitInformatique from "../../pages/SingleProduitInformatique";
import EspaceCollab from "../../pages/EspaceCollab";
import Fournisseurs from "../../pages/Fournisseurs";
import Publications from "../../pages/Publications";
import OutilsMarketing from "../../pages/OutilsMarketing";
import ComitesExpertises from "../../pages/ComitesExpertises";
import { baseUrlForEnvironment } from "./Axios";

interface RouteData {
	icon?: IconDefinition;
	key: string;
	path: string;
    title?: string;
    element: any;
    order: number;
    children?: RouteObject[],
}

export const routes: RouteData[] = [
    {
        "order": -1,
        "key": "connexion",
        "path": "/connexion",
        "element": <Connexion/>
    },
    {
        "order": 10,
        "icon": faCartFlatbed,
        "key": "fournisseurs",
        "path": "/fournisseurs",
        "title": "Fournisseurs",
        "element": <Fournisseurs />
    },
    {
        "order": 9,
        "icon": faNewspaper,
        "key": "publications",
        "path": "/publications",
        "title": "Publications",
        "element": <Publications />
    },
    {
        "order": 8,
        "icon": faPenRuler,
        "key": "outils-marketing",
        "path": "/outils-marketing",
        "title": "Outils marketing",
        "element": <OutilsMarketing />
    },
    {
        "order": 7,
        "icon": faComputer,
        "key": "produits-informatiques",
        "path": "/produits-informatiques",
        "title": "Produits informatiques",
        "element": <ProduitsInformatique />,
        "children": [
            { "path": "/produits-informatiques/:id", "element": <SingleProduitInformatique /> }
        ],
    },
    {
        "order": 6,
        "icon": faHandshake,
        "key": "espace-collaboratrices",
        "path": "/espace-collaboratrices",
        "title": "Espace collaboratrices",
        "element": <EspaceCollab />
    },
    {
        "order": 5,
        "icon": faStar,
        "key": "comites-dexpertises",
        "path": "/comites-dexpertises",
        "title": "Comités d'expertises",
        "element": <ComitesExpertises />
    },
    {
        "order": 4,
        "icon": faUsersLine,
        "key": "conseil-dadministration",
        "path": "/conseil-dadministration",
        "title": "Conseil d\'administration",
        "element": <ConseilAdmin />
    },
    {
        "order": 3,
        "icon": faPhone,
        "key": "appels-doffres-offres-de-service",
        "path": "/appels-doffres-offres-de-service",
        "title": "Appel d’offres / Offres de service",
        "element": <AppelOffres />
    },
    {
        "order": 2,
        "icon": faCircleQuestion,
        "key": "questions-reponses",
        "path": "/questions-reponses",
        "title": "Questions / réponses",
        "element": <Questions />
    },
    {
        "order": 1,
        "icon": faHouse,
        "key": "accueil",
        "path": "/",
        "title": "Accueil",
        "element": <Accueil/>
    }
]