import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import Pagination from '../components/Pagination';
interface ListProps {
    data: any[];
    tabs: {title: string, keys: string[], isTheBiggest?: boolean}[];
   
}

function ListComponent({data, tabs}: ListProps) {
    const [currentPage, setCurrentPage] =React.useState(1);
    const [popupClassName, setPopupClassName] =React.useState('popup-copy');
    const [postePerPage] =React.useState(10);

    const tabsDisplayed = tabs.map((tab: any, index: number) => {
        return <p key={index} className={tab.isTheBiggest ? 'bigger' : ''}>{tab.title}</p>
    });

    const indexOfLastPost = currentPage * postePerPage;
    const indexOfFirstPost = indexOfLastPost - postePerPage;
    const currentPosts = data.slice(indexOfFirstPost,indexOfLastPost)

    const paginate = (pageNumber:any) => setCurrentPage(pageNumber);
        
    const copyToClipboard = (index: any, text: any) => {
      navigator.clipboard.writeText(text)

      const element: any = document.querySelector("#popup-"+index);
      element.classList.toggle("active");
      setTimeout(() => {
          element.classList.toggle("active");
      }, 700)
    }

    const elementsList = currentPosts?.map((element:any, elIndex: number) => {
        return (
            <div key={elIndex}  className={`list-members-component__container__content`}>
            {
                tabs.map((tab: any, index: number) => {
                    let itemTest = element;
                    let finalItem:any =[]; 
                    
                    tab.keys.forEach((key: any, index: number) => {
                        finalItem.push(itemTest[key]);
                    });

                    if(tab.title === 'Contact'){
                        return (
                            <div key={index} className= {tab.isTheBiggest ? 'contact-info bigger' : 'contact-info'}>
                                <a onClick={e => { e.preventDefault(); copyToClipboard(elIndex, finalItem[0])} } href={"tel:" + finalItem[0]} className='phone'>{finalItem[0]}</a>
                                <a onClick={e => { e.preventDefault(); copyToClipboard(elIndex, finalItem[1])} } className="mail-icon" href={"mailto:" + finalItem[1]} ><FontAwesomeIcon icon={faEnvelope} /></a>
                                <div id={'popup-'+elIndex} className={popupClassName}>Copié</div>
                            </div>
                        );
                    }
                    else{
                        return (
                            <p key={index} className={tab.isTheBiggest ? 'bigger' : ''} dangerouslySetInnerHTML={{ __html: finalItem }}></p>
                        );
                    }
                   
                })
            }
            </div>
        );
    });

    return (
        <div>
            <div className='list-members-component__container__tabs'>
                {tabsDisplayed}
            </div>
            <div className="list-component__container__list">
                <div className='list-items'>{elementsList} </div>
                
                <Pagination postePerPage={postePerPage} currentPage={currentPage} totalPosts={data.length} paginate={paginate}/>
            </div>
        </div>
    );
}

export default ListComponent;
