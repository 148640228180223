import { faBars, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import logo from '../resources/images/logo_PME.png';
import { baseUrlForEnvironment } from "./utils/Axios";
import { getToken, getUser } from "./utils/Common";



function Header({onMobileMenuOpen, pageName}: any) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState<boolean>(false);
  function handleMobileMenuOpen() {
    setIsMobileMenuOpen(prevValue => !prevValue);
    onMobileMenuOpen(!isMobileMenuOpen);
  }

  return (
    <header className={pageName == 'connexion' ? 'header connexion' : 'header'}>
        <a className='header__link' href={baseUrlForEnvironment}>
            Retour sur le site
        </a>
        <div className='header__mobile'>
          <div className="header__mobile__menu">
            <a href="/">
              <img className='header__mobile__menu__logo' src={logo} alt='Logo' />
            </a>
            <div className="header__mobile__user">
              <h2>Bonjour</h2>
              <div className='user-info'>
                  <p>{getUser()?.firstName} {getUser()?.lastName}</p>
              </div>
            </div>
            <button className="header__mobile__menu__mobile" onClick={() => handleMobileMenuOpen()}>
                <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
          
        </div>
    </header>
  );
}

export default Header;
