import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSwiper, Swiper, SwiperSlide } from 'swiper/react';
import DomainsSwiperNavButtons from '../components/DomainsSwiperNavButtons';
import {isMobile} from 'react-device-detect';
import NonSwiperTabs from '../components/NonSwiperTabs';
import ComitesExpertisesByTabSimplified from './ComitesExpertisesByTabSimplified';
import { act } from 'react-dom/test-utils';

const ComitesExpertisesDisplay = ({domainData, activeTab}: any) => {
	const [selectedTab, setSelectedTab] = useState('documents');
	const [tabData, setTabData] = useState({});
	const [tabsName, setTabsName] = useState<any>();

	useEffect(() => {		
			setTabsName(domainData?.keys);

	}, [activeTab, domainData]);

	useEffect(() => {
			const dd = domainData?.data?.filter((d:any) => { return d.key === selectedTab});
			setTabData(dd);
	}, [selectedTab, domainData]);

	const tabs = tabsName?.map((tab: {key: string, title: string}, index: number) => {
	    return (
	        <div className={`tab ${selectedTab === tab.key ? 'active' : ''}`} key={tab.key} onClick={() => setSelectedTab(tab.key)}>
	            <span className='title'>{tab.title}</span> <span className='count'></span>
	        </div>
	    );
	});

	return (
		<>
			{ 
				domainData && domainData.hasOwnProperty('keys') ? 
               		<div>
               		    <div className="tabs overflow-y">
               		        {tabs}
               		    </div>
               		    {<div>
               		    { tabData ?
               		    	<ComitesExpertisesByTabSimplified dataByTab={tabData} />
               		    	: null
               		    }
               		    </div>}
               		</div>
               	: null

			}

		</>
	);
}

export default ComitesExpertisesDisplay;