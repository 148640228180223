import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

interface TabsProps {
    tabsName: {key: string, title: string}[];
    tabsCount?: number[];
    onTabSelected?: any;
  }

const Tabs = ({tabsName, tabsCount, onTabSelected}: TabsProps) => {
    const [tabSelected, setTabSelected] = React.useState('');

    const handleTabSelected = (tabName: string) => {
        setTabSelected(tabName);
        onTabSelected(tabName);
    }
    
    React.useEffect(() => {
        if(tabsName) {
            // setTabSelected(tabsName[0].key);
            if (onTabSelected) {
                onTabSelected(tabsName[0].key); 
            }
        }
    }, []);

    const tabs = tabsName?.map((tab: {key: string, title: string}, index: number) => {
        return (
            <SwiperSlide className={`tab ${tabSelected === tab.key ? 'active' : ''}`} key={tab.key} onClick={() => handleTabSelected(tab.key)}>
                <span className='title'>{tab.title}</span> <span className='count'>{tabsCount ? tabsCount[index] : null}</span>
            </SwiperSlide>
        );
    });

    return (
        <Swiper 
            spaceBetween={32}
            slidesPerView='auto'
            freeMode={true}
            grabCursor={true}
            className='tabs'>
            {tabs}
        </Swiper>
    );
}

export default Tabs;
