import * as React from 'react';
import Layout from '../components/Layout';
import { Outlet } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ToolsTab from '../components/ToolsTab';
import {pagesAxios} from '../components/utils/Axios';
import Breadcrumbs from '../components/Breadcrumbs';
import Loading from '../components/Loading';

function ProduitsInformatique() {
  let { id } = useParams();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any>({});

  React.useEffect(() => {
    pagesAxios.get('produits-informatiques').then(response => {
      setData(response.data.data);
      setLoading(false);
    }
  )},[]);

  const getComputerProducts = () => {
    let sections:any = {};
    data?.fields?.items.forEach((i:any) => {
        if (!sections.hasOwnProperty(i.section)) {
          sections[i.section] = [];
        }
        sections[i.section].push(i)
    });
    return (<ToolsTab content={sections} linkKey='link' buttonTitle='Consultez'/>);
  }

  return (
    <div>
      {
        id ?
        <Outlet />
        :
        <Layout title={data?.title} classes="produits-info-page">
          {loading ? <Loading /> : 
            <div className='produits-info-page__container inner-div'>
              <h1>{data?.title}</h1>
              <Breadcrumbs />
              { 
                data.notice ?
                  <div className="products-notice" dangerouslySetInnerHTML={{ __html: data.notice }}></div>
                : void(0)
              }
              {getComputerProducts()}
            </div>
          }
        </Layout>
      }
    </div>
  );
}

export default ProduitsInformatique;
