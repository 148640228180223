import * as React from 'react';
import { useState, useEffect } from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import Layout from '../components/Layout';
import Loading from '../components/Loading';
import {pagesAxios} from '../components/utils/Axios';

function Questions() {

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>({});

    useEffect(() => {
        pagesAxios.get('questions-reponses').then(response => {
            setData(response.data.data);
            setLoading(false);
        }
    )},[]); 

  return (
    <Layout title="Questions Réponses" classes="question-page">
        {loading ? <Loading /> : 
        <div className='inner-div'>
            <div className='question-page__title'>
                <h1 dangerouslySetInnerHTML={{__html: data.title}}></h1>
                <Breadcrumbs />
            </div>

            <div className='question-page__text'>
                <p dangerouslySetInnerHTML={{__html: data.content}}></p>
            </div>

            <div className='question-page__social-media'>
                {data.fields.social_networks.map(function(el:any, index:number) {
                    return(
                        <a key={index} className='social-button' target={el.link.target} href={el.link.url}>
                            <img className='social-icon' src={el.icon.link} alt={el.icon.title} />    
                        </a>
                    )
                })}
            </div>

            <div className='question-page__links'>
            {data.fields.links.map(function(el:any, index: number) {
                    return(
                        <div key={index} className='link-card'>
                            <h3 dangerouslySetInnerHTML={{__html: el.title}}></h3>
                            <a href={el.link.url} target={el.link.target} >{el.link.title}</a>
                        </div>
                    )
                })}
            </div>
        </div>
        }
    </Layout>
  );
}

export default Questions;
