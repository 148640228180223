import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useState, useEffect } from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import Layout from '../components/Layout';
import Loading from '../components/Loading';
import TenderModal from '../components/TenderModal';
import TenderFormModal from '../components/TenderFormModal';
import Searchbar from '../components/Searchbar';
import Tabs from '../components/Tabs';
import {pagesAxios} from '../components/utils/Axios'; 
import { getToken } from '../components/utils/Common';

function AppelOffres() {
    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [tabSelected, setTabSelected] = useState<string>('');
    const [dataSelected, setDataSelected] = useState<any>([]);
    const [dataFiltered, setDataFiltered] = useState<any>([]);
    const [tenderCountByCategory, setTenderCountByCategory] = useState<number[]>([]);
    const [showModal, setShowModal] = React.useState(false);
    const [openedForm, setOpenedForm] = React.useState<any>({});
    const [tenderTitle, setTenderTitle] = React.useState<string>('');
    const [submitResponseSuccess, setSubmitResponseSuccess] = React.useState<string>('');
    const [submitResponseError, setSubmitResponseError] = React.useState<string>('');
    const [showTenderForm, setShowTenderForm] = React.useState<boolean>(false);


    useEffect(() => {
        pagesAxios.get('appels-doffres').then(response => {
            const receivedData = response.data.data;
            setData(receivedData);
            setTabSelected(receivedData?.fields?.keys[0].key);
            setLoading(false);
        });
    },[]);

    useEffect(() => {
        if (openedForm?.id) {
            setTenderTitle(openedForm?.title);
            setShowModal(true);
        }
    }, [openedForm]);

    useEffect(() => {
        if (showModal === false) {
            setTenderTitle('');
            setOpenedForm({});
        }
    }, [showModal]);

    useEffect(() => {
        if(data && data?.fields?.tenders) {
            let tenderSelected: any[] = [];
            const countTender: number[] = [];
            if(dataFiltered.length > 0) {
                tenderSelected = dataFiltered.filter((tender:any) => tender.key === tabSelected);
                dataFiltered?.forEach((tender:any) => {
                    countTender.push(tender.tenders.length);
                });
                setDataSelected(tenderSelected[0].tenders);
            }
            setTenderCountByCategory(countTender);
        }
    },[tabSelected, dataFiltered]); 

    const postForm = () => {

        const field = (document.getElementById("tenderpost-textarea") as HTMLInputElement);
        const value = field?.value;
        const formData = new FormData();
        formData.append('message', value);
        formData.append('tender_id', openedForm.id);
        pagesAxios.post(data?.fields?.form_action, formData, 
            { headers: {"Authorization" : `Bearer ${getToken()}`} })
        .then(response => {
          if(response.data.statusCode === 403) {
              setSubmitResponseSuccess('');
              setSubmitResponseError(response?.data?.data?.message);
              setShowModal(false);
          }
          setSubmitResponseError('');
          setSubmitResponseSuccess(response?.data?.data?.message);
          setShowModal(false);
        }).catch(error => {
          setSubmitResponseSuccess('');
          setSubmitResponseError(error?.data?.data?.message);
          setShowModal(false);
        });
    }

    const getTenderAuthor = (tender:any) => {
        return `${tender?.published_by?.user_lastname}, ${tender?.published_by?.user_firstname}`;
    }

    const setFormResponse = (message: string, isError: boolean) => {

        if (isError) {
          setSubmitResponseSuccess('');
          setSubmitResponseError(message);
        } else {
            setSubmitResponseError('');
            setSubmitResponseSuccess(message);
        }
        setShowTenderForm(false);
    }

    const tendersDisplayed = dataSelected?.map((tender:any, index:number) => {

        const tenderTitlePrefix = (tender?.tender_type === 'request') ? 'Appel d\'offres' : 'Offre de service';
        return (
            <div key={index} className='appel-doffres__tender'>
                <div className="tender-reply-btn">
                    <h4> <span className="">{tenderTitlePrefix} - </span> {tender?.title }</h4>
                    <a onClick={() => setOpenedForm(tender)} className='generic-button--dark-blue flex items-center justify-between gap-5' href="#">
                        <FontAwesomeIcon icon={faPlus}/> Postuler
                    </a>
                </div>
                <p className="font-bold text-lg pb-4">{tender?.published_by?.office}</p>
                <p className={tender?.published_by ? 'block' : 'hidden'}>
                    <strong>Publié par : </strong>
                    <span dangerouslySetInnerHTML={{ __html: getTenderAuthor(tender) }}></span>
                </p>
                <p className={tender?.posted_on ? 'block' : 'hidden'}>
                    <strong>Le : </strong>{tender?.posted_on}
                </p>
                <p className={tender?.expiry_date?.date ? 'block' : 'hidden'}>
                    <strong>Date d'échéance : </strong>{tender?.expiry_date?.date}
                </p>
                <p className={tender?.content ? 'block py-3' : 'hidden'}>
                    {tender?.content}
                </p>
                <p className={tender?.city ? 'block' : 'hidden'}>
                    <strong>Nom du responsable : </strong>{tender?.manager}
                </p>
                <p className={tender?.city ? 'block' : 'hidden'}>
                    <strong>Ville : </strong>{tender?.city}
                </p>
                <p className={tender?.domains?.post_title ? 'block' : 'hidden'}>
                    <strong>Secteurs : </strong>{tender?.domains?.post_title}
                </p>
                <p className={tender?.fee ? 'block' : 'hidden'}>
                    <strong>Montant alloué pour l’execution du mandat : </strong>{tender?.fee}
                </p>
            </div>
        );
    });

    return (
        <Layout title="Appels d'offres / Offres de services" classes="appel-doffres">
            {loading ? <Loading /> :
                <div className='appel-doffres__container inner-div'>
                    <h1>Appels d'offres / Offres de services</h1> 
                    <Breadcrumbs />
                    <div className='flex flex-col-reverse md:flex-row items-center gap-5 justify-between mt-10 md:mt-20'>
                        <Searchbar data={data} onDataFiltered={setDataFiltered} firstKey='tenders' secondKey='tenders' noFilter={false} />
                        <a onClick={() => setShowTenderForm(true) } className='generic-button--dark-blue flex items-center justify-between gap-5' href="#">
                            <FontAwesomeIcon icon={faPlus}/> {data?.fields?.create_link?.title}
                        </a>
                    </div>
                    {
                        submitResponseSuccess !== '' ? 
                            <div className="mt-6 p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800">
                                {submitResponseSuccess}
                            </div>
                        : null
                    }
                    {
                        submitResponseError !== '' ? 
                            <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800">
                                {submitResponseError}
                            </div>
                        : null
                    }
                    <div className='appel-doffres__tabs'>
                        <Tabs tabsName={data?.fields?.keys} tabsCount={tenderCountByCategory} onTabSelected={setTabSelected} />
                    </div>
                    <div>
                        {dataSelected.length > 0 ? tendersDisplayed : <h4 className='my-10 px-9'>Pas d'appel d'offres pour cette catégorie</h4>}
                    </div>

                    { showModal ?
                        <TenderModal
                            closeModal={() => setShowModal(false)}
                            saveModal={() => postForm() }
                            tenderTitle={tenderTitle}
                        />
                        : null
                    }
                    { showTenderForm ?
                        <TenderFormModal
                            closeModal={setFormResponse}
                            saveModal={() => postForm() }
                            formData={data.fields}
                        />
                        : null
                    }
                </div>
            }
        </Layout>
    );
}

export default AppelOffres;
