import * as React from 'react';
import { useState, useEffect } from 'react';
import {pagesAxios} from '../components/utils/Axios'; 
import TenderForm from '../components/TenderForm';
import { getToken } from '../components/utils/Common';

interface TenderFormModalProps {
    closeModal: Function,
    saveModal: Function,
    formData: any
}

const TenderFormModal = ({closeModal, saveModal, formData}: TenderFormModalProps) => {
    
   let offices: Array<number> = [];
  const updateOfficeValues = (v: any) => {
    offices = v.map((e: any) => e.value);
  }
  const saveTender = () => {
    const form = (document.getElementById("tender-publish") as HTMLFormElement)
    const fdata = new FormData(form);
    
    pagesAxios.post(formData?.publish_action, fdata,
      { headers: {"Authorization" : `Bearer ${getToken()}`} }
    ).then(response => { 
      closeModal(response?.data?.data?.data?.message, false);
     } ).catch((error) => {
      closeModal(error?.data?.data?.data?.message, true);
     });
  }

	return (
		<div className="publish-tender-modal overflow-y-scroll">
			<div
            className="justify-center items-center flex overflow-x-hidden overflow-y-scroll fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="publish-tender-modal-content border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Publier un appel d'offre
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => closeModal()}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="overflow-y-scroll publish-tender-modal-content relative p-6 flex-auto">
                  <TenderForm data={formData} setSelectedValues={updateOfficeValues} />
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => closeModal()}
                  >
                    Fermer
                  </button>
                  <button
                    className="generic-button--dark-blue flex items-center justify-between gap-5"
                    type="button"
                    onClick={() => saveTender()}
                  >
                    Publier
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
	);
}

export default TenderFormModal;
