import * as React from 'react';
import { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import {pagesAxios} from '../components/utils/Axios';
import Loading from '../components/Loading';
import Breadcrumbs from '../components/Breadcrumbs';
import ComitesExpertisesByDomains from '../components/ComitesExpertisesByDomains';
import ComitesExpertisesTab from '../components/ComitesExpertisesTab';
import ComitesExpertisesDisplay from '../components/ComitesExpertisesDisplay';

function ComitesExpertises() {
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>({});
    const [domainSelected, setDomainSelected] = useState<string>('');
    const [dataByDomainSelected, setDataByDomainSelected] = useState<any>([]);

    useEffect(() => {
        pagesAxios.get('comite-dexpertises').then(response => {
            setData(response.data.data);
            setLoading(false);
        }
    )}, []); 

    return (
        <Layout title="Conseil d'administration" classes="comites-expertises-page">
            {loading ? <Loading /> : 
            <div className='inner-div'>
                <div className='comites-expertises__title'>
                    <h1>Comités d'expertises</h1>
                    <Breadcrumbs />
                </div>

                {/*<ComitesExpertisesByDomains dataByDomain={data} />*/}
                <ComitesExpertisesTab data={data} />
                {/*<ComitesExpertisesDisplay data={data} selectedDomain={domainSelected} />*/}
            </div>
            }
        </Layout>
    );
}

export default ComitesExpertises;
