import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSwiper, Swiper, SwiperSlide } from 'swiper/react';
import DomainsSwiperNavButtons from '../components/DomainsSwiperNavButtons';
import {isMobile} from 'react-device-detect';
import ComitesExpertisesDisplay from '../components/ComitesExpertisesDisplay';

const ComitesExpertisesTab = ({data}: any) => {
    const [activeTab, setActiveTab] = useState('');
    const [domainData, setDomainData] = useState('');

    useEffect(() => {
			let dData = data.filter((tab: any) => tab.slug === activeTab);

			setDomainData(dData[0]);
    }, [activeTab]);

    const getSlidesPerView = () => {
        if (isMobile) {
            return 2;
        }
        return 3;
    }

	const setTabs = (tabKey: string) => {
		setActiveTab(tabKey);
	}

	const getTabs = () => {
		return data?.map((tab: {key: string, title: string}, index: number) => {
	        return (
	            <div className={`tab ${activeTab === tab.key ? 'active' : ''}`} key={tab.key} onClick={() => setTabs(tab.key)}>
	                <span className='title'>{tab.title}</span>
	            </div>
	        );
	    });
	}
	return (
		<div>
				<div
			        className='domains-tabs'>
			            { getTabs() }
			    </div>
			    <div className="content-container">
			    	<ComitesExpertisesDisplay domainData={domainData} activeTab={activeTab} />
			    </div>
		</div>
		
	);
}

export default ComitesExpertisesTab;