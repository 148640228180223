import * as React from 'react';
import { useState, useEffect } from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import Layout from '../components/Layout';
import Loading from '../components/Loading';
import {pagesAxios} from '../components/utils/Axios';

function Fournisseurs() {
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>({});
    
    useEffect(() => {
        pagesAxios.get('fournisseurs').then(response => {
            setData(response.data.data);
            setLoading(false);
        }
    )}, []); 

    const providersDisplayed = data?.fields?.providers?.map((provider: any, index: number) => {
        return (
            <div key={index} className="p-5">
            {
                provider?.fields?.logo ?
                    <a href={provider?.fields?.link?.url} target={provider?.fields?.link?.target}>
                        <img src={provider?.fields?.logo} alt='logo' />
                    </a> 
                : null
            }
            </div>
        )
    });

    return (
        <Layout title={data?.title} classes="fournisseurs-page">
            {loading ? <Loading /> : 
                <div className='fournisseurs-page__container inner-div'>
                    <h1>{data?.title}</h1>
                    <Breadcrumbs />
                    <div className='fournisseurs-page__container__logos'>
                        {providersDisplayed}
                    </div> 
                </div>
            }
        </Layout>
    );
}

export default Fournisseurs;
