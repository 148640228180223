import * as React from 'react';
import './resources/styles/index.scss';
import { RouteObject, useRoutes } from "react-router-dom";
import { routes } from './components/utils/Routes';

function App() {
  let routesObject: RouteObject[] = routes;

  let element = useRoutes(routesObject);
  return (
    <div>
      {element}
    </div>
  )
}

export default App;