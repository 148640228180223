import * as React from 'react';
import { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import {pagesAxios} from '../components/utils/Axios';
import Tabs from '../components/Tabs';
import ToolsTab from '../components/ToolsTab';
import FoldersTab from '../components/FoldersTab';
import ListComponent from '../components/ListComponent';
import ListMembersComponent from '../components/ListMembersComponent';
import ListComponentCollab from '../components/ListComponentCollab';
import Searchbar from '../components/Searchbar';
import Loading from '../components/Loading';
import Breadcrumbs from '../components/Breadcrumbs';
import DropdownComite from '../components/DropdownComite';

function ConseilAdministration() {
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>({});

    const [yearSelected, setyearSelected] = useState<string>('');
    const [tabSelected, setTabSelected] = useState<string>('');
    const [yearArray, setYearArray] = useState<any>([]);
    const [yearPDFSelected, setYearPDFSelected] = useState<any>([]);
    const [dataSelected, setDataSelected] = useState<any>([]);
    const [dataFiltered, setDataFiltered] = useState<any>([]);
    const [tenderCountByCategory, setTenderCountByCategory] = useState<number[]>([]);
    const [categoryFilter, setCategoryFilter] = useState<any>([]);
    const [cloneCategorieSelected, setCloneCategorieSelected] = useState<any>([]);
    const [searchValue, setSearchValue] = useState<any>('');
    var _ = require('lodash');
    useEffect(() => {
        pagesAxios.get('espaces-collaboratrices').then(response => {
            setData(response.data.data);
            setTabSelected(response.data.data?.fields?.keys[0].key);
            setCloneCategorieSelected(_.cloneDeep([response.data.data.fields]))
            setLoading(false);
        }
    )},[]); 

    

    useEffect(() => {
        if(data && data?.fields?.data) {
            setSearchValue('');
            let CategorySelected: any[] = [];
            CategorySelected = data?.fields?.data.filter((cat:any) => cat.key === tabSelected);

            if(CategorySelected[0].key=='records' && yearArray.length < 1){
                var yearsList:any[] = [];
                CategorySelected[0].items.map(function(item:any) {
                    yearsList.push(item.year)
                })
                yearsList.sort((a, b) => a < b ? 1 : -1)
                setYearArray([...new Set(yearsList)]);
            }
            setDataSelected(CategorySelected[0]);
        }
    },[tabSelected]); 

    useEffect(() => {
        if(data && data?.fields?.data) {
            let clone = _.cloneDeep(data?.fields?.data);
            let CategorySelected = clone.filter((tab: any) => tab.key === tabSelected);

            if (searchValue) {
                if (CategorySelected[0].key !== "committee") {
                  CategorySelected[0].items = CategorySelected[0].items.filter((item:any) => {
                      return item?.title?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
                  });  
              } else if (CategorySelected[0].key === "committee") {
                  CategorySelected[0].items = CategorySelected[0].items.filter((item:any) => {
                      return item?.first_name?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 
                      || item?.last_name?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
                  });
              }
                
            }

            if (categoryFilter && categoryFilter.length > 0 && CategorySelected[0].key === "documents") {

                CategorySelected[0].items = CategorySelected[0].items.filter((item:any) => {
                    let isValid = false;
                    item.categories?.forEach((cat: any) => {
                        if (cat.slug === categoryFilter[0]?.searchTerm) {
                            isValid = true;
                        }
                    });

                    return isValid;
                });
            }
            

            setDataSelected(CategorySelected[0]);
        }
    },[categoryFilter, dataFiltered,cloneCategorieSelected]); 

    function handleYearSelected(year: string) {
        setyearSelected(year)
        let pdfYearSelected: any[] = [];
        pdfYearSelected = dataSelected?.items.filter((d:any) => d.year === year);
        setYearPDFSelected(pdfYearSelected);
    }

    const getComponent:any = (type: any) => {
        switch (type) {
            case "documents":
               
                return (
                    <div>
                         <ListComponent 
                            data={dataSelected?.items} 
                            tabs={[
                                {title: 'Nom', keys:['name'], isTheBiggest: true}, 
                                {title: 'Dernière MAJ', keys: ['updated_at']}, 
                                {title: 'Taille', keys: ['file', 'filesize']}, 
                                {title: 'Catégorie', keys: ['categories']}
                            ]} 
                            linkKeys={['file', 'link']}
                        />
                    </div>
               
                );
            case "records":
                return (<FoldersTab yearArray={yearArray} yearSelected={yearSelected} yearPDFSelected={yearPDFSelected} onYearSelected={handleYearSelected} />);
            case "tools":

                let sections:any = {};
                dataSelected?.items.forEach((i:any) => {
                    if (!sections.hasOwnProperty(i.section)) {
                        sections[i.section] = [];
                    }
                    sections[i.section].push(i)
                })
                return (<ToolsTab content={sections} linkKey='link' buttonTitle="S'informez"/>);
            default:
             return (<ListComponentCollab 
                data={dataSelected?.items} 
                tabs={[
                    {title: 'Nom', keys:['last_name']}, 
                    {title: 'Prénom', keys: ['first_name']}, 
                    {title: 'Étude', keys: ['office'], isTheBiggest: true}, 
                    {title: 'Contact', keys: ['phone','email'],isTheBiggest: true},
                ]} 
            />);
        }
     }

     const getDropDown:any = (type: any) => {
        if(type == 'documents'){
         return (
             <DropdownComite categories={dataSelected?.categories} data={data?.fields?.data} dataClone={cloneCategorieSelected[0]} onDataSelected={setCategoryFilter} isEspaceCollab={true} />
             );
        }
      }
   
  return (
    <Layout title="Conseil d'administration" classes="espace-collab-page">
        {loading ? <Loading /> : 
        <div className='inner-div'>
            <div className='espace-collab-page__title'>
                <h1>Espace collaboratrices</h1>
                <Breadcrumbs />
            </div>

            <div className='espace-collab-page__tabs'>
                <Tabs tabsName={data?.fields?.keys} tabsCount={tenderCountByCategory} onTabSelected={setTabSelected} />
                
            </div>
            <div className='espace-collab-page__filters'>
                <Searchbar data={cloneCategorieSelected[0]} onDataFiltered={setDataFiltered} firstKey='data' secondKey='items' noFilter={false}  setValue={(value: any) => setSearchValue(value)}/>
                {dataSelected?.categories?.length > 0 ?  getDropDown(dataSelected.key) : ''}
            </div>
             
            <div>
                {dataSelected?.items?.length > 0 ?  getComponent(dataSelected.key) : <h4 className='my-10 px-9'>Pas de contenu pour cette catégorie</h4>}
            </div>
        </div>
        }
    </Layout>
  );
}

export default ConseilAdministration;
