import * as React from 'react';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

interface SearchbarProps {
    data: any,
    onDataFiltered: any,
    firstKey: string, 
    secondKey: string, 
    noFilter: any 
    setValue?: any 
}

function Searchbar({ data, onDataFiltered, firstKey, secondKey, noFilter, setValue }: SearchbarProps ) {
    const [searchValue, setSearchValue] = useState<string>('');

    useEffect(() => {
        setSearchValue('');
    }, [data]);

    useEffect(() => {
        if (setValue) {
            setValue(searchValue);
        }
        if (noFilter) {
           onDataFiltered(searchValue);
           return;
        }
        if(searchValue.length > 0) {
            let dataFilteredByFirstKey:any[] = [];
            if(data?.fields !== undefined) {
                data?.fields[firstKey]?.forEach((filesByFirstKey:any, index: number)=> {
                    const filteredElements:any[] = [];
                    filesByFirstKey[secondKey].forEach((element:any)=> {
                        for (let key in element) {
                            if (typeof element[key] === 'string') {
                                if (element[key]?.toLowerCase()?.includes(searchValue.toLowerCase())) {
                                    filteredElements.push(element);
                                }
                            } else if(typeof element[key] === 'object') {
                                for (let key2 in element[key]) {
                                    if (typeof element[key][key2] === 'string' && element[key][key2]?.toLowerCase()?.includes(searchValue.toLowerCase())) {
                                        filteredElements.push(element);
                                    }
                                }
                            }
                        }
                    });
                    const dataFiltered: any = {
                        title: filesByFirstKey?.title,
                        key: filesByFirstKey?.key
                    };
                    dataFiltered[secondKey] = filteredElements.filter((el, index, array) => array.indexOf(el) === index)
                    dataFilteredByFirstKey.push(dataFiltered);
                });
            } else if(data[firstKey] !== undefined) {
                data[firstKey].forEach((filesByFirstKey: any) => {
                    const filteredElements:any[] = [];
                    filesByFirstKey[secondKey].forEach((element:any)=> {
                        for (let key in element) {
                            if (typeof element[key] === 'string') {
                                if (element[key]?.toLowerCase()?.includes(searchValue.toLowerCase())) {
                                    filteredElements.push(element);
                                }
                            } else if(typeof element[key] === 'object') {
                                for (let key2 in element[key]) {
                                    if (typeof element[key][key2] === 'string' && element[key][key2]?.toLowerCase()?.includes(searchValue.toLowerCase())) {
                                        filteredElements.push(element);
                                    }
                                }
                            }
                        }
                    });
                    
                    const filteredCategories:any[] = [];
                    if(filesByFirstKey.categories){
                        filesByFirstKey.categories.forEach((element:any)=> {
                            filteredCategories.push(element)
                        });
                    }
        
                    const dataFiltered: any = {
                        title: filesByFirstKey?.title,
                        key: filesByFirstKey?.key
                    };
                    dataFiltered[secondKey] = filteredElements.filter((el, index, array) => array.indexOf(el) === index)
                    dataFiltered.categories = filteredCategories.filter((el, index, array) => array.indexOf(el) === index)
                    dataFilteredByFirstKey.push(dataFiltered); 
                });
            } else {
                let filesByFirstKey = data[0];
                const filteredElements:any[] = [];
                filesByFirstKey[secondKey].forEach((element:any)=> {
                    for (let key in element) {
                        if (typeof element[key] === 'string') {
                            if (element[key]?.toLowerCase()?.includes(searchValue.toLowerCase())) {
                                filteredElements.push(element);
                            }
                        } else if(typeof element[key] === 'object') {
                            for (let key2 in element[key]) {
                                if (typeof element[key][key2] === 'string' && element[key][key2]?.toLowerCase()?.includes(searchValue.toLowerCase())) {
                                    filteredElements.push(element);
                                }
                            }
                        }
                    }
                });
                
                const filteredCategories:any[] = [];
                if(filesByFirstKey.categories){
                    filesByFirstKey.categories.forEach((element:any)=> {
                        filteredCategories.push(element)
                    });
                }
    
                const dataFiltered: any = {
                    title: filesByFirstKey?.title,
                    key: filesByFirstKey?.key
                };
                dataFiltered[secondKey] = filteredElements.filter((el, index, array) => array.indexOf(el) === index)
                dataFiltered.categories = filteredCategories.filter((el, index, array) => array.indexOf(el) === index)
                dataFilteredByFirstKey.push(dataFiltered); 
            }
            onDataFiltered(dataFilteredByFirstKey);
        } else {
            onDataFiltered([]);
            if(data?.fields) {
                onDataFiltered(data?.fields[firstKey]);
            }
        }
    },[searchValue]);

    return (
        <form className="generic-searchbar searchbar searchbar-mobile -single">
            <div className="article-choice-div">
                <input type="text" className="article-choice" placeholder="Recherche..." value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                <label><FontAwesomeIcon className='text-orange' icon={faSearch} /><input className="submit-btn" /></label>
            </div>
        </form>
    );
}

export default Searchbar;
