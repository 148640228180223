import * as React from 'react';
import { useState } from 'react';
import FoldersTab from '../components/FoldersTab';
import ListComponent from '../components/ListComponent';
import ListMembersComponent from '../components/ListMembersComponent';

const ComitesExpertisesFiltered = ({dataFiltered, tabSelected, doYearSelected}: any) => {
    const [yearSelected, setyearSelected] = useState<string>('');
    const [yearArray, setYearArray] = useState<any>([]);
    const [yearPDFSelected, setYearPDFSelected] = useState<any>([]);

    React.useEffect(() => {
        if(tabSelected === 'records') {
           var yearsList:any[] = [];
            dataFiltered.forEach((item:any) => {
                if (item.hasOwnProperty('year') && yearsList.indexOf(item.year) === -1) {
                    yearsList.push(item.year);
                }
            });
            yearsList.sort((a, b) => a < b ? 1 : -1);
            setYearArray([...new Set(yearsList)]); 
        }
        let pdfYearSelected = dataFiltered?.filter((d:any) => d.year === yearSelected);
        setYearPDFSelected(pdfYearSelected);

    }, [tabSelected, dataFiltered]);

    function handleYearSelected(year: string) {
        setyearSelected(year)
        doYearSelected(year)
        let pdfYearSelected: any[] = [];
        pdfYearSelected = dataFiltered?.filter((d:any) => d.year === year);
        setYearPDFSelected(pdfYearSelected);
    }
    
    return (
        <div>
            {dataFiltered?.length > 0 ?  
                tabSelected === 'documents' ?
                    <ListComponent 
                        data={dataFiltered} 
                        tabs={[
                            {title: 'Nom', keys:['name'], isTheBiggest: true}, 
                            {title: 'Dernière MAJ', keys: ['updated_at']}, 
                            {title: 'Taille', keys: ['file', 'filesize']}, 
                            {title: 'Catégorie', keys: ['categories']}
                        ]} 
                        linkKeys={['file', 'link']}
                    />
                : tabSelected === 'records' && yearArray[0] !== ["undefined"] ?
                    <FoldersTab 
                        data={dataFiltered}
                        yearArray={yearArray} 
                        yearSelected={yearSelected} 
                        yearPDFSelected={yearPDFSelected} 
                        onYearSelected={handleYearSelected} 
                    />
                : 
                    <ListMembersComponent 
                        data={dataFiltered} 
                        tabs={[
                            {title: 'Nom', keys:['last_name']}, 
                            {title: 'Prénom', keys: ['first_name']}, 
                            {title: 'Étude', keys: ['office'], isTheBiggest: true}, 
                            {title: 'Contact', keys: ['phone','email'],isTheBiggest: true},
                        ]} 
                    />
            : <h4 className='my-10 px-9'></h4>}
        </div>
    );
}

export default ComitesExpertisesFiltered;
