import * as React from 'react'; 

function Dropdown({data, firstDataSelected, onDataSelected}: any) {
  const [dataSelected, setDataSelected] = React.useState<any>();

  React.useEffect(() => {
    setDataSelected(firstDataSelected);
  }, []);

  const dropdownDisplayed = data.map((value: number, index: number) => {
    return <option key={index} value={value}>{value}</option>
  });

  function handleDataSelected(value: any) {
    setDataSelected(value);
    onDataSelected(value);
  }

  return (
    <div className='dropdown'>
        <select value={dataSelected} onChange={(event) => handleDataSelected(event.target.value)}>
        {dropdownDisplayed}
        </select>
    </div>
  );
}

export default Dropdown;
