import * as React from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import Dropdown from '../components/Dropdown';
import Layout from '../components/Layout';
import Loading from '../components/Loading';
import {pagesAxios} from '../components/utils/Axios'; 

function Publications() {
  const [data, setData] = React.useState<any>({});
  const [years, setYears] = React.useState<number[]>([]);
  const [yearSelected, setYearSelected] = React.useState<number>();
  const [dataSelected, setDataSelected] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    pagesAxios.get('publications').then(response => {
        const receivedData = response.data.data;
        setData(receivedData);
        const yearList = new Set<number>();
        receivedData?.fields?.publications?.forEach((data: any) => {
            yearList.add(data.year);
        });
        setYears(Array.from(yearList).sort((a, b) => b - a));
        setLoading(false);
    });
  }, []);

  React.useEffect(() => {
    setYearSelected(years[0]);
  }, [years]);

  React.useEffect(() => {
    const dataFilteredByYear = data?.fields?.publications?.filter((publication: any) => publication.year == yearSelected);
    setDataSelected(dataFilteredByYear); 
  }, [data, yearSelected]);

  const publicationsDisplayed = dataSelected?.map((publication: any, index: number) => {
      if (!publication?.pdf) { return null; }
      return (
        <div key={index}>
          {
              publication?.pdf ?
                <div className='publication'>
                    <img src={publication?.cover?.url} alt={publication?.cover?.description} />
                    <div className='content'>
                      <p className='content__title'>{publication.title}</p>
                      <p className='content__description' dangerouslySetInnerHTML={{__html: publication?.content}}></p>
                      <a href={publication?.pdf} target='_blank' rel="noreferrer" className='generic-button--dark-blue content__button'>Consulter</a>
                    </div>
                </div>
            : null
          }
        </div>
      );
  });
  
  return (
    <div>
        <Layout title={data?.title} classes="publications">
          { loading ? <Loading /> :
            <div className='publications__container inner-div'>
                <h1 dangerouslySetInnerHTML={{__html: data?.title}}></h1>
                <Breadcrumbs />
                <Dropdown data={years} firstDataSelected={yearSelected} onDataSelected={setYearSelected} />
                <div className='publications__grid'>
                    {publicationsDisplayed}
                </div>
            </div>
          }
        </Layout>
    </div>
  );
}

export default Publications;
