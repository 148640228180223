import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import Pagination from './Pagination';
interface ListProps {
    data: any[];
    tabs: {title: string, keys: string[], isTheBiggest?: boolean}[];
   
}

function ListComponentCollab({data, tabs}: ListProps) {
    const [currentPage, setCurrentPage] =React.useState(1);
    const [postePerPage] =React.useState(10);
    const [popupClassName, setPopupClassName] =React.useState('popup-copy');

    const tabsDisplayed = tabs.map((tab: any, index: number) => {
        return <p key={index} className={tab.isTheBiggest ? 'bigger' : ''}>{tab.title}</p>
    });

    const indexOfLastPost = currentPage * postePerPage;
    const indexOfFirstPost = indexOfLastPost - postePerPage;
    const currentPosts = data.slice(indexOfFirstPost,indexOfLastPost)

    const paginate = (pageNumber:any) => setCurrentPage(pageNumber);
        
    const copyToClipboard = (index: any, text: any) => {
      navigator.clipboard.writeText(text)

      const element: any = document.querySelector("#popup-"+index);
      element.classList.toggle("active");
      setTimeout(() => {
          element.classList.toggle("active");
      }, 700)
    }

    const elementsList = currentPosts?.map((element:any, index: number) => {
        return (
            <div key={index}  className={`list-members-component__container__content`}>
            {
                tabs.map((tab: any, index: number) => {
                    let itemTest = element;
                    let finalItem:any =[]; 
                        
                    tab.keys.forEach((key: any, index: number) => {
                        finalItem.push(itemTest[key]);
                    });

                    if(tab.title === 'Contact'){

                        return (
                            <div key={index} className= {tab.isTheBiggest ? 'contact-info bigger' : 'contact-info'}>
                                <a  href={"tel:" + itemTest.phone} className='phone'>{itemTest.phone}</a>
                                <a className="mail-icon" href={"mailto:" + itemTest.email} ><FontAwesomeIcon icon={faEnvelope} /></a>
                            </div>
                        );
                    }
                    else{
                        return (
                            <p key={index} className={tab.isTheBiggest ? 'bigger' : ''} dangerouslySetInnerHTML={{ __html: finalItem}}></p>
                        );
                    }
                   
                })
            }
            </div>
        );
    });

    return (
        <div>
            <div className='list-members-component__container__tabs'>
                {tabsDisplayed}
            </div>
            <div className="list-component__container__list">
                <div className='list-items'>

                {
                    currentPosts?.map((element:any, index: number) => {
                        return (
                            <div key={index} className={`list-members-component__container__content`}>

                                <p>{element.last_name}</p>
                                <p>{element.first_name}</p>
                                <p className="bigger" dangerouslySetInnerHTML={{ __html: element.office}}></p>
                                <div key={index} className='bigger contact-info contact-info-collab'>
                                    <a onClick={e => { e.preventDefault(); copyToClipboard(index, element.phone)} } href={"tel:" + element.phone} className='phone'>{element.phone}</a>
                                    <a onClick={e => { e.preventDefault(); copyToClipboard(index, element.email)} } className="mail-icon" href={"mailto:" + element.email} ><FontAwesomeIcon icon={faEnvelope} /></a>
                                    <div id={'popup-'+index} className={popupClassName}>Copié</div>
                                </div>
                            </div>
                            
                        )
                    })
                }

                </div>
                
                <Pagination postePerPage={postePerPage} currentPage={currentPage} totalPosts={data.length} paginate={paginate}/>
            </div>
        </div>
    );
}

export default ListComponentCollab;
