import * as React from 'react';
import { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import {pagesAxios} from '../components/utils/Axios';
import Tabs from '../components/Tabs';
import MembersTab from '../components/MembersTab';
import FoldersTab from '../components/FoldersTab';
import Breadcrumbs from '../components/Breadcrumbs';
import Loading from '../components/Loading';


function ConseilAdministration() {
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>({});

    const [yearSelected, setyearSelected] = useState<string>('');
    const [tabSelected, setTabSelected] = useState<string>('');
    const [yearArray, setYearArray] = useState<any>([]);
    const [yearPDFSelected, setYearPDFSelected] = useState<any>([]);
    const [dataSelected, setDataSelected] = useState<any>([]);

    useEffect(() => {
        pagesAxios.get('conseil-dadministration').then(response => {
           
            setData(response.data.data);
            setTabSelected(response.data.data?.fields?.keys[0].key);
            setLoading(false);
        }
    )},[]); 

    useEffect(() => {
        if(data && data?.fields?.data) {
            let CategorySelected: any[] = [];
            CategorySelected = data?.fields?.data.filter((cat:any) => cat.key === tabSelected);
            if(CategorySelected[0].key === 'records' && yearArray.length < 1){
                var yearsList:any[] = [];
                CategorySelected[0].items.forEach((item:any) => {
                    yearsList.push(item.year)
                })
                yearsList.sort((a, b) => a < b ? 1 : -1)
                setYearArray([...new Set(yearsList)]);
            }
            setDataSelected(CategorySelected[0]);
        }
    },[tabSelected]); 

    function handleYearSelected(year: string) {
        setyearSelected(year)
        let pdfYearSelected: any[] = [];
        pdfYearSelected = dataSelected?.items.filter((d:any) => d.year === year);
        setYearPDFSelected(pdfYearSelected);
    }

    const getComponent:any = (type: any) => {
        switch (type) {
           case "members":
             return (<MembersTab items={dataSelected?.items} />);
           default:
             return (<FoldersTab yearArray={yearArray} yearSelected={yearSelected} yearPDFSelected={yearPDFSelected} onYearSelected={handleYearSelected} />);
        }
     }

    
   
  return (
    <Layout title="Conseil d'administration" classes="conseil-admin-page">
        {loading ? <Loading /> : 
        <div className='inner-div'>
            <div className='conseil-admin-page__title'>
                <h1  dangerouslySetInnerHTML={{__html: data.title}}></h1>
                <Breadcrumbs />
            </div>

            <div className='conseil-admin-page__tabs'>
                <Tabs tabsName={data?.fields?.keys} onTabSelected={setTabSelected} />
            </div>
            
            <div>
                {dataSelected?.items?.length > 0 ?  getComponent(dataSelected.key) : <h4 className='my-10 px-9'>Pas d'appel d'offres pour cette catégorie</h4>}
            </div>
        </div>
        }
    </Layout>
  );
}




export default ConseilAdministration;
