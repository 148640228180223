import * as React from 'react';
import { useState, useEffect } from 'react';
import {tokenAxios, baseUrlForEnvironment} from '../components/utils/Axios';
import { removeUserSession, setUserSession } from '../components/utils/Common';
import { useNavigate } from 'react-router-dom';
import logo from '../resources/images/logo_PME.png';
import ConnexionImageBack from '../resources/images/connexion_image_back.svg';
import ConnexionImageMiddle from '../resources/images/connexion_image_middle.svg';
import ConnexionImageFront from '../resources/images/connexion_image_front.svg';
import Header from '../components/Header';

function Connexion() {
    const navigate = useNavigate();
    const username = useFormInput('');
    const password = useFormInput('');
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [isRememberSelected, setIsRememberSelected] = useState<boolean>(false);

    useEffect(() => {
        removeUserSession();
    }, []);

    const handleLogin = (e:any) => {
        e.preventDefault()
        setError('');
        setLoading(true);
        tokenAxios.post('', 
            { username: username.value, password: password.value, remember: isRememberSelected })
        .then(response => {
            setLoading(false);
            setUserSession(response.data.data.token, response.data.data);
            navigate('/');
        }).catch(error => {
            setLoading(false);
            if (error.response.status === 401) setError(error.response.data.message);
            else setError("La connexion s'est mal passée. Veuillez réessayer.");
        });
    }

    return (
        <React.Fragment>
            <header>
                <title>PME Internotaire extranet - Connexion</title>
                <link rel="icon" type="image/png" href="/images/favicon.png" />
            </header>

            <Header pageName='connexion'/>
            <main className="login-page" >
                <div className='login-page__background connexion'>
                    <div className='login-page__background__column1'></div>
                    <div className='login-page__background__column2'>
                        <div className='login-page__background__column2__container'>
                            <img src={ConnexionImageBack} alt='Network' />
                            <img src={ConnexionImageMiddle} alt='Network' />
                            <img src={ConnexionImageFront} alt='Network' />
                        </div>
                    </div>
                </div>
                <form className='login-page__columns' onSubmit={handleLogin}>
                    <div className='login-page__columns__content'>
                        <img src={logo} alt='Logo' />
                        <h1>Extranet</h1>
                        <h2>Connexion</h2>
                        <input placeholder='Identifiant ou adresse de messagerie' type="text" {...username} autoComplete="new-password" />
                        <input placeholder='Mot de passe' type="password" {...password} autoComplete="new-password" />
                        {error && <p className='error-message'>{error}</p>}
                        <label className='remember-checkbox'>
                            <input
                                type="checkbox"
                                name='remember'
                                checked={isRememberSelected}
                                onChange={() => setIsRememberSelected(prevValue => !prevValue)}
                                className=""
                            />
                            Se souvenir de moi
                        </label>
                        <input type="submit" value={loading ? 'Chargement...' : 'Se connecter'} disabled={loading} /><br />
                        <a href={`${baseUrlForEnvironment}/wp-login.php?action=lostpassword`} className='password-link'>Mot de passe oublié</a>
                    </div>
                    <div className='login-page__columns__image'>
                    </div>
                </form>
            </main>
        </React.Fragment>
    );
}



const useFormInput = (initialValue: any) => {
    const [value, setValue] = useState(initialValue);
   
    const handleChange = (e: any) => {
      setValue(e.target.value);
    }
    return {
      value,
      onChange: handleChange
    }
}
   

export default Connexion;
