import * as React from 'react';
import { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import {pagesAxios} from '../components/utils/Axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {routes} from '../components/utils/Routes'
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Loading from '../components/Loading';


function Accueil() {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>({});

  useEffect(() => {
    pagesAxios.get('accueil').then(response => {
      setData(response.data.data);
      setLoading(false);
    }
  )},[]);

  return (
    <Layout title="Accueil" classes="accueil-page">
        {loading ? <Loading />
        : 
        <div className="inner-div">
            <div className='accueil-page__title'>
                <h1 dangerouslySetInnerHTML={{__html: data.title}}></h1>
            </div>

            <div className='accueil-page__fast-access'>
              <h2>Accès rapide</h2>
                <div className='fast-access-flex'>
                {data.fields.quick_access.map(function(el:any,i:number) {
                    let index = routes.findIndex(element =>element.key == el.url)
                    return(
                        <a className="fast-access-card" key={i} href={routes[index].path}>
                          <h4>{routes[index].title}</h4>
                          <FontAwesomeIcon icon={routes[index].icon as IconProp} /> 
                        </a>
                    )
                })}
                </div>
            </div>

            <div className="accueil-page__important-message">
              <h2>Messages importants</h2>
              <h3>{data.fields.notice.title}</h3>
              <div dangerouslySetInnerHTML={{__html: data.fields.notice.description}}></div>
              <div className='message-list'>
                <ul>
                  {data.fields.notice.link_list.map(function(el:any,index:number) {
                    return(
                      <li key={index}>
                        <a href={el.link.url} target={el.link.target}>{el.link.title}</a>
                      </li>
                      
                    )
                  })}
                </ul>
              </div>
            </div>

            <div className='accueil-page__verbal-proces'>
                  <h2>Dernier PV du CA</h2>
                  <p dangerouslySetInnerHTML={{__html: data.fields.last_record.content }}></p>
                  <a className='generic-button--light-blue' href={data.fields.last_record.link}>Consultez</a>
            </div>

        </div>
        }
    </Layout>
  );
}

export default Accueil;
