import * as React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

const Breadcrumbs = () => {
    const breadcrumbs = useBreadcrumbs();

    const breadcrumbDisplayed = breadcrumbs.map(({ breadcrumb }: any, index: number) => {
        let title = '';
        if(breadcrumb.props.children === 'Home') {
            title = 'Accueil';
        } else if(breadcrumb.props.children === 'Appels doffres offres de service') {
            title = 'Appels d’offres / Offres de service';
        } else {
            title = breadcrumb.props.children;
        }
        return <a key={index} href={breadcrumb.key}><span className='title'>{title}</span><span className={index === breadcrumbs.length-1 ? 'hidden' : 'inline'}>&nbsp;&gt;&nbsp;</span></a>;
    });

    return (
        <div className='breadcrumbs'>
            {breadcrumbDisplayed}
        </div>
    )
}

export default Breadcrumbs;
