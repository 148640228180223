import * as React from 'react';
import { useState, useEffect } from 'react';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';

interface TenderFormProps {
    data: any,
    setSelectedValues: Function
}
const TenderForm = ({data, setSelectedValues}: TenderFormProps) => {

    return (
         <>
             <div className="w-full">
               <form id="tender-publish" className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
               <div className="mb-4 flex flex-col justify-start w-fit">
                <label className="block text-gray-700 text-sm font-bold mb-2 flex justify-between" htmlFor="tender_type">
                  Appel d’offres
                  <input type="radio" defaultChecked value="request" name="tender_type" className="ml-2"/>
                </label>
                <label className="block text-gray-700 text-sm font-bold mb-2 flex justify-between" htmlFor="tender_type">
                  Offre de service
                  <input type="radio" value="offer" name="tender_type" className="ml-2"/> 
                </label>
              </div>
                 <div className="mb-4">
                   <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
                     Titre <span className="text-red-500">*</span>
                   </label>
                   <input name="title" required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="title" type="text" placeholder="Titre" />
                 </div>
                 <div className="mb-6">
                   <label 
                   className="block text-gray-700 text-sm font-bold mb-2"
                   htmlFor="description"
                   >
                     Description <span className="text-red-500">*</span>
                   </label>
                   <textarea 
                   name="description"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="description">
                   </textarea>
                 </div>

                 <div className="mb-6">
                   <label 
                   className="block text-gray-700 text-sm font-bold mb-2"
                   htmlFor="manager">
                    Nom du responsable <span className="text-red-500">*</span>
                   </label>
                   <input name="manager" required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="manager" type="text" placeholder="Nom" />
                 </div>


                 <div className="mb-6">
                   <label 
                   className="block text-gray-700 text-sm font-bold mb-2"
                   htmlFor="city">
                     Ville <span className="text-red-500">*</span>
                   </label>
                   <input name="city" required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="city" type="text" placeholder="Ville" />
                 </div>

                 <div className="mb-6">
                   <label 
                   className="block text-gray-700 text-sm font-bold mb-2"
                   htmlFor="fee">
                     Montant alloué <span className="text-red-500">*</span>
                   </label>
                   <input name="fee" required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="fee" type="text" placeholder="Montant alloué" />
                 </div>

                 <div className="mb-6">
                   <label 
                   className="block text-gray-700 text-sm font-bold mb-2"
                   htmlFor="file">
                     Fichier
                   </label>
                   <input name="file" required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="file" type="file" />
                 </div>

                 <div className="mb-6">
                   <label 
                   className="block text-gray-700 text-sm font-bold mb-2"
                   htmlFor="expiry_date">
                     Date d'échéance <span className="text-red-500">*</span>
                   </label>
                   <input name="expiry_date" required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="expiry_date" type="date" />
                 </div>

                 <div className="mb-6">
                   <label 
                   className="block text-gray-700 text-sm font-bold mb-2"
                   htmlFor="show_for">
                     Choisir les études qui pourront voir l’appel d’offre
                   </label>
                   <p className="text-gray-600 text-sm">Laisser vide pour que toutes les études aient accès à l’affichage.</p>
                   <Select
                       name="show_for[]"
                       isMulti={true}
                       options={data?.form?.offices}
                       onChange={(v) => setSelectedValues(v)}
                    />
                 </div>
               </form>
             </div>
         </>
    );
};

export default TenderForm