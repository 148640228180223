import * as React from 'react';
import { useState, useEffect } from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import Layout from '../components/Layout';
import Loading from '../components/Loading';
import ToolsTab from '../components/ToolsTab';
import {pagesAxios} from '../components/utils/Axios';

function OutilsMarketing() {
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>({});
    
    useEffect(() => {
        pagesAxios.get('outils-marketing').then(response => {
            setData(response.data.data);
            setLoading(false);
        }
    )},[]); 

    const getMarketingTools = () => {
        let sections:any = {};
        data?.items.forEach((i:any) => {
            if (!sections.hasOwnProperty(i.section)) {
                sections[i.section] = [];
            }
            sections[i.section].push(i)
        });
        return (<ToolsTab content={sections} linkKey='link' buttonTitle='Consultez'/>);
    }
   
    return (
        <Layout title={data?.title} classes="outils-marketing-page">
            {loading ? <Loading /> : 
                <div className='outils-marketing-page__container inner-div'>
                    <h1>{data?.title}</h1>
                    <Breadcrumbs />
                   {getMarketingTools()}
                </div>
            }
        </Layout>
    );
}

export default OutilsMarketing;
